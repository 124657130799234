import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApidataService } from '../apidata.service';
import { ProductModel } from '../models/ProductModel';
import { MediaModel } from '../models/MediaModel';

import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass'],
  providers: [ApidataService]
})
export class ProductComponent implements OnInit,OnDestroy  {
  faGooglePlay = faGooglePlay;
  
  private sub: any;
  Images: Array<any>;

  product:ProductModel;

  constructor(private route: ActivatedRoute,
    private _apiDataService: ApidataService
    ) {
    this.product= new ProductModel();
    this.Images=new Array<any>();
  }

  LoadImages(){
    this.product.media?.forEach((imgObj:MediaModel)=> {
      if(imgObj.type=="IMAGE"){
        this.Images.push({image:imgObj.url, alt:this.product.name, thumbImage:imgObj.url});
      }
    });
    console.log(this.Images);
    console.log(this.product.media);
  }
  
   ngOnInit(): void {
     this.sub = this.route.params.subscribe(params => {
       this.showResponse(params['sku']);
    });
    
    
  }

  showResponse(sku:string) {
    let url=`https://function-bapublic.azurewebsites.net/api/product/sku/${sku}`;
    this._apiDataService.getFeatured(url)
      // resp is of type `HttpResponse<Config>`
      .subscribe(resp => {
        this.product=resp;
        this.LoadImages();
      });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
