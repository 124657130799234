
<div class="container-sm">
    <!--Carrousel-->
    <div class="col-md-6 offset-md-3">
       <ng-image-slider #nav
[images]="Images"
[manageImageRatio]="true"
[infinite]="false"
[autoSlide]="1"
[imageSize]="{width: '100%', height: '62%'}"
slideImage="1"></ng-image-slider>
    </div>
    <!--Actions-->
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <div class="itemName">{{product.name}}</div>
            <div class="itemPrice">{{product.displayPrice| currency: 'Q'}}</div>
        </div>
    </div>
    <!--Description-->
    <div class="row">
        <div class="col-md-6 offset-md-3 itemDescription" >
        {{product.description}}
        <br>
        <br>
        <br>
        </div>
        
    </div>
    <div class="row" >
        <div class="col-md-6 offset-md-3 div-download" >            
        Descarga la aplicacion <b>BuscandoAndo</b> para ver más caracteristica de esta propiedad y de muchas otras.<br><br>
        <a href="https://play.google.com/store/apps/details?id=net.mightydevs.BuscandoAndo&hl=es" class="btn btn-primary"><fa-icon [icon]="faGooglePlay"></fa-icon> Descargar</a>
    </div>
    </div>
    <div class="row verticaPlaceholder">

    </div>
</div>

<!--footer-->
<footer class="footer">
    <div class="container">
      <span class="text-muted">© 2021 BuscandoAndo | Desarrollado por <b>MightyDevs</b></span>
    </div>
  </footer>