import { MediaModel } from "./MediaModel";

export class ProductModel{
	constructor(
public id?:string,
public sku?:string,
public isActive?:boolean,
public isVerifiedUser?:boolean,
public country?:string,
public productType?:string,
public productTypeDescription?:string,
public name?:string,
public title?:string,
public urlImage?:string,
public sourceUrl?:string,
public description?:string,
public media?:Array<MediaModel>,
public videos?:Array<string>,
public displayPrice?:string,
public nameMainLocation?:string,
public descriptionMainLocation?:string,
public addressMainLocation?:string,
public Phones?:Array<string>,
public logo?:string,
public dealType?:string,
public tags?:Array<string>,
public isDelete?:boolean,
public CreationDate?:Date
        ) { }
}