import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ProductModel } from './models/ProductModel';

@Injectable({
  providedIn: 'root'
})
export class ApidataService {
  configUrl = 'assets/config.json';
  constructor(private http: HttpClient) { }

  getFeatured(url:string): Observable<ProductModel> {
    let headers = new Headers({ 'Content-Type': 'application/json' });

    let options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'json' as const
    };
    return this.http.get<ProductModel>(url, options)
    .pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
